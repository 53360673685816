<template>
  <div class="testing__wrapper">
    <div class="testing__wrapper-run">
      <testing-started
        v-if="isStarted"
        :is-done="isDone"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <testing-not-started v-else v-bind="$attrs" v-on="$listeners" />
    </div>
    <div class="testing__wrapper-info">
      <testing-info v-bind="$attrs" v-on="$listeners" />
      <testing-comment class="mt-2" v-bind="$attrs" v-on="$listeners" />
    </div>
  </div>
</template>
<script>
export default {
  name: "testing-run",
  components: {
    testingNotStarted: () => import("./testingNotStarted.vue"),
    testingStarted: () => import("./testingStarted.vue"),
    testingInfo: () => import("./testingInfo.vue"),
    testingComment: () => import("./testingComment.vue"),
  },
  props: {
    isStarted: {
      type: Boolean,
      default: false,
    },
    isDone: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.testing {
  &__wrapper {
    display: flex;
    &-run {
      flex-grow: 1;
      margin-right: 61px;
    }
  }
}
</style>
